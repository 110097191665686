/* In your global.css or a CSS module */
.minHeight-2rem {
  min-height: 2rem;
}

/* Apply the style only for screens smaller than Tailwind's 'md' breakpoint */
@media (min-width: 768px) {
  .minHeight-2rem {
    min-height: 0; /* or any other value you wish to apply for 'md' and up */
  }
}

/* Loading Logo spinner */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  border-top-color: transparent !important; /* Make the top border transparent */
  animation: spin 1s linear infinite; /* Spin animation */
}